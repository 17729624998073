.container{
    &-links{
    align-items: start;
    flex-wrap: wrap;
    width: 344px;
        &-social{
        flex-basis: 100%;
        display: flex;
        margin-top: 34px;
        > a{
            margin-left: 32px;
            > img{
                height: 32px;
                border-radius: 0px;
                width: 32px;
            }
        }
        :first-child{
            margin-left: 0px;
        }
        }
    }
    &-1{
    width:144px;
    margin-bottom: 16px;
    }
    &-2{
    width:200px;
    margin-bottom: 16px;
    }
    &-languageselector-appbadges{
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        margin-top: 36px;
    }
    &-appbadges{
        min-width: 0px !important;
        padding-top: 10px;
        > a{
            margin-left: 20px;
            > img{
                width: 136px;
                height: 40px;
                border-radius: 0px;
            }  
        }
        :first-child{
            margin-left: 0px;
        }
    }
}

.languageselector-appbadges:last-child {
    order: -1; /* Moves the last item to the beginning */
  }
.languageselector-appbadges:nth-child(1) {
    margin-top: 32px !important;
  }

@media only screen and (min-width: 768px) {
  .container{
        &-links{
        width:420px;
            &-social{
            flex-basis: 0%;
            flex-grow: 1;
            margin-top: 0px;
            justify-content: end;
            > a{
                margin-left: 40px;
            }
            :first-child{
                margin-left: 0px;
            }
            }  
        }
        &-1,&-2{
        width: 199px;
        }
        &-languageselector-appbadges{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
         margin-top: 4px;
        }
    }

    .languageselector-appbadges:last-child {
    order: 0; /* Moves the last item to the no where */
    }
    .languageselector-appbadges {
    margin-top: 32px !important;
    }

}


@media only screen and (min-width: 1360px) {
  .container{
        &-links{
        width:820px;
            &-social{
                margin-top: 16px;
            > a{
                margin-left: 56px;
            }
            :first-child{
                margin-left: 0px;
            }
            }  
        }
        &-1,&-2{
        width: 272px;
        }
        &-languageselector-appbadges{
        margin-top: 44px;
    }
    }
}
